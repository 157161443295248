import "./general-prev-next.scss";
import "./control-prev.scss";
import {
  CarouselAdditionInitializationBlock,
  forEachRegisteredCarouselController,
} from "../carousel-handles";
import { CarouselController } from "../controller/carousel-controller";
import { currentCarouselQuerySelectorAll } from "../selector/current-selector";

export const CAROUSEL_PREV_CLASS_NAME = "carousel-control-prev";

const initializePrevControlElements: CarouselAdditionInitializationBlock = (
  carouselRootElement,
  controller
) => {
  for (const prevControlElement of currentCarouselQuerySelectorAll(
    carouselRootElement,
    `.${CAROUSEL_PREV_CLASS_NAME}`
  )) {
    prevControlElement?.addEventListener("click", () =>
      scrollToPrevSlide(controller)
    );
  }
};

/**
 * Return the target index of the "scroll to previous" function.
 *
 * The resulting motion wraps to the last slide when the first slide is
 * currently shown.
 */
const scrollToPrevSlide = (controller: CarouselController) => {
  const overflowIndex = controller.options.loop
    ? controller.getNumberOfSlides() - 1
    : controller.getCurrentIndex();

  if (
    controller.getCurrentIndex() - 1 >= controller.getNumberOfSlides() ||
    controller.getCurrentIndex() <= 0
  ) {
    controller.slideTo(overflowIndex);
  } else {
    controller.slideTo(controller.getCurrentIndex() - 1);
  }
};

forEachRegisteredCarouselController(initializePrevControlElements);
